.textarea {
  &__subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--dark-200);
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 4px;
    }
  }
  &__body {
    position: relative;
    .input {
      width: 100%;
      height: 140px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border-radius: 12px 12px 12px 12px;
      border: 2px solid #EFEFEF;
      padding: 15px;
      outline: none;
      resize: none;
      &::placeholder {
        color: var(--gray-500);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .button {
      width: 65px;
      bottom: 5px;
    }
  }
}
@media(max-width: 667px) {
  .textarea {

  }
}