.accordion {
  &__title {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }
  &__hr {
    width: 100%;
    height: 1px;
    background: #EFEFEF;
    border-radius: 1px;
    margin-top: 13px;
    margin-bottom: 23px;
  }
  &__icon {
    transition: all .3s;
    &_active {
      transform: rotate(180deg);
    }
  }
  &__content {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #85868C;
    margin-top: 10px;
    border-bottom: 1px #EFEFEF;
  }
}
@media (max-width: 375px) {
  .accordion {
    &__title {
      span {
        font-size: 15px;
      }
    }
  }
}