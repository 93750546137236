.forgot {
  &__email {
    max-width: 376px;
    width: 100%;
    margin: 0px auto;
  }
  &__field {
    margin-top: 20px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  &__email {
    &-button {
      margin-top: 12px;
    }
  }
  &__form {
    max-width: 296px;
    width: 100%;
  }
  &__description {
    margin-bottom: 20px;
  }
}
@media (max-width: 902px) {
  .forgot {
    &__email {
      max-width: 296px;
      width: 100%;
      margin: 0px auto;
    }
  }
}