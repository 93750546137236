.home {
  &__analytics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    margin-top: 12px;
    padding: 26px;
    background-color: var(--gray-100);
    &-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .home__analytic {
      &-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      &-item {
        width: 50%;
        margin-top: 0;
        .item_icon {
          display: flex;
          align-items: center;
          span {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: var(--gray-500);
            margin-left: 8px;

          }
        }
        .item_data {
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 50px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .item_progress-bar {
          max-width: 114px;
          width: 100%;
          height: 2px;
          background: blue;
        }
      }
    }
  }
}

@media (max-width: 902px) {
  .home {
    &__analytics {
      .home__analytic {
        &-items {
          display: flex;
          flex-direction: column;
        }
        &-item:last-child {
          margin-top: 20px;
        }
      }
    }
  }
}
@media (max-width: 540px) {
  .home {
    &__analytics {
      padding: 16px;
      .home__analytic {
        &-item {
          width: 100%;
        }
      }
    }
  }
}