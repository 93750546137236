.input {
  display: flex;
  align-items: center;
  max-width: 100%;
  border-radius: 12px;
  padding: 12px;
  background-color: var(--gray-100);
  border: 2px solid transparent;
  input {
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: var(--dark-100);
    width: 100%;
    &::placeholder {
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      color: #9A9FA5;
    }
    &:-moz-read-only {
      color: rgb(116, 112, 112);
      cursor: default;
    }
    &:read-only {
      color: rgb(116, 112, 112);
      cursor: default;
    }
  }
  &__icon {
    margin-right: 14px;
  }
  &.error {
    background-color: var(--danger-100);
    .input__arrow {
      cursor: pointer;
      margin-right: 14px;
    }
    input {
      color: var(--danger-200);
    }
  }
  &.success {
    background-color: transparent;
    border: 2px solid #9A9FA5;
  }
  &.focus {
    transition: all .3s;
  }
  &.phone {
    input {
      padding-left: 17px;
    }
  }
  &.focus:focus-within {
    border: 2px solid #CABDFF !important;
  }
  .password-eye {
    cursor: pointer;
  }
}

.label-error {
  display: inline-block;
  color: var(--danger-200);
  font-size: 12px;
  line-height: 14px;
  margin-top: 2px;
  font-weight: bold;
}
