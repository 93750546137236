.paginate {
    margin-top: 12px;
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        margin: 0 auto;
        max-width: 300px;
        width: 100%;
        .next {
            max-width: 60px;
            margin-left: 50px;
            width: 100%;
        }
        .previous {
            white-space: nowrap;
            margin-right: 50px;
            a {
                position: relative;
                padding-left: 15px;
                &::before {
                    top: -2px;
                    left: 0px;
                    content: url('../../../public/assets/icons/paginate-purple.svg');
                    transform: rotate(180deg);
                    position: absolute;
                    margin-right: 10px;
                }
            }
            &.disabled {
                a {
                    &::before {
                        top: -1px;
                        content: url('../../../public/assets/icons/paginate-gray.svg');
                        transform: rotate(0deg);
                    }
                }
            }
            
        }
        .next {
            white-space: nowrap;
            a {
                position: relative;
                padding-right: 15px;
                &::after {
                    top: 0px;
                    right: 0px;
                    content: url('../../../public/assets/icons/paginate-purple.svg');
                    transform: rotate(0deg);
                    position: absolute;
                    margin-left: 10px;
                }
            }
            &.disabled {
                a {
                    &::after {
                        top: -2px;
                        content: url('../../../public/assets/icons/paginate-gray.svg');
                        transform: rotate(180deg);
                    }
                }
            }
            
        }
        .next,
        .previous {
            cursor: pointer;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            &.disabled {
                color: #95A5A6;
            }
            color: #5E5CE6;
        }
        li {
        }
        .page {
            a {
                display: inline-block;
                font-weight: 700;
                font-size: 14px;
                line-height: 32px;
                text-align: center;
                letter-spacing: 0.21px;
                color: #2C3E50;
                min-width: 40px;
                cursor: pointer;
                transition: all 0.3s;
                
            }
            &.active {
                a {
                    background: #5E5CE6;
                    border-radius: 6px;
                    color: #FFFFFF;
                }
            } 
        }
    }
}
@media (max-width: 920px) {
    .paginate {
        ul {
            .next {
                white-space: nowrap;
                margin-left: 2px;
            }
            .previous {
                white-space: nowrap;
                margin-right: 2px;
            }
            .page.active {
                margin-right: 7px;
                margin-left: 7px;
            }
        }
    }
}