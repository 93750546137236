.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  left: 0px;
  max-width: 256px;
  width: 100%;
  height: 100vh;
  background-color: var(--light-100);
  padding: 27px;
  z-index: 99;
  &__logo {
    cursor: pointer;
  }

  &__list {
    list-style: none;
    margin-top: 48px;
  }
  &__item {
    margin-top: 8px;

  svg {
    width: 24px;
    height: 24px;
  }
    &:first-child {
      margin-top: 0px;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 12px;
    background-color: transparent;
    border-radius: 12px;
    &.active {
      background: var(--gray-300);
      box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF;
      .text {
        color: var(--dark-300);
      }
    }
    .icon {
      margin-right: 12px;
    }
    .text {
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      color: var(--dark-200);

    }
  }
  &__logout {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 12px;
    background-color: transparent;
    border-radius: 12px;
    cursor: pointer;
    &.active {
      background: var(--gray-300);
      box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF;
      .text {
        color: var(--dark-300);
      }
    }
    .icon {
      margin-right: 12px;
    }
    .text {
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      color: var(--dark-200);

    }
  }
  .hr {
    height: 2px;
    width: 100%;
    background: #F4F4F4;
    border-radius: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    box-sizing: inherit;
  }
}



@media (max-width: 767px) {
  .nav {
    display: flex;
    flex-direction: initial;
    justify-content: center;
    position: fixed;
    top: auto;
    max-width: 100%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 76px;
    background-color: var(--light-100);
    padding: 27px;
    &__top {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      max-width: 342px;
      width: 100%;
    }
    &__list {
      max-width: 342px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0;
    }
    &__link {
      flex-direction: column;
      align-items: center;
      padding: 0;
      .icon {
        margin-right: 0;
      }
      &.active {
        background: none;
        box-shadow: none;
        .text {
          color: var(--dark-300);
        }
      }
    }
    &__item {
      margin-top: 0;
    }
    &__logo {
        display: none;
    }
    &__bottom {
      display: none;
      svg {
        display: none;
      }
      .text {
        display: none;
      }
    }
  }
}
@media (max-width: 375px) {
    .nav {
      &__link {
        .text {
          font-size: 14px;
        }
      }
    }
}