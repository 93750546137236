.signup {
  &__email {
    max-width: 296px;
    width: 100%;
    margin: 0px auto;
  }
  &__field {
    margin-top: 20px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  &__email {
    &-button {
      margin-top: 12px;
    }
  }
}
