.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  border: none;
  outline: none;
  transition: all 0.3s;
  cursor: pointer;
  padding: 12px 0px;
  border-radius: 12px;

  &__loader {
    margin-right: 5px;
    margin-top: -2px;
    animation: spin 1s infinite;
  }
  &:disabled {
    opacity: 0.6;
    pointer-events:none;
  }
  &:hover {
    transform: translateY(-3px);
  }
  &:active {
    transform: translateY(0px);
  }
  &.success {
    color: var(--light-100);
    background-color: var(--success-100);
  }
  &.light {
    color: rgba(60, 60, 67, 0.6);
    background: #FCFCFC;
    border: 2px solid #EFEFEF;
  }
  &.purple {
    color: var(--light-200);
    background-color: var(--purple-100);
  }
  &__icon {
    margin-top: -2px;
    margin-right: 8px;
  }
}

@keyframes spin{
  0%{
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%{
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
