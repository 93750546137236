:root {
  --light-100: #FCFCFC;
  --light-200: #FFFFFF;
  --gray-100: #F4F4F4;
  --gray-200: #9A9FA5;
  --gray-300: #EFEFEF;
  --gray-400: #6F767E;
  --gray-500: #85868C;
  --dark-100: #1A1D1F;
  --dark-200: #6E7A80;
  --dark-300: #2C3E50;
  --dark-400: #272B30;
  --danger-100: rgba(255, 188, 153, 0.25);
  --danger-200: #FF6A55;
  --success-100: #1E3E23;
  --success-200: #83BF6E;
  --purple-100: #5856D6;
  --orange-100: #FFBC99;
  --orange-200: #FF9500;
  --purple-200: #5E5CE6;
  --red-100: #E11900;
  --red-200: #FF6A55;
  --blue-100: #B1E5FC;
}
