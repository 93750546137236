.help {
  &__main {
    max-width: 1000px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--light-100);
    padding: 48px;
    margin-top: 16px;
    margin-bottom: 76px;
  }
}
@media( max-width: 667px) {
  .help {
    &__main {
      padding: 20px;
    }
  }
}