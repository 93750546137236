.expenses {
  .main__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 50px;
      color: #2C3E50;
    }
    &-buttons {
      display: flex;
      .button:first-child {

      }
      .button {
        background: var(--light-100);
        border-radius: 8px;
        padding: 12px 20px;
        margin-left: 16px;
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 24px;
          letter-spacing: -0.01em;
          color: var(--dark-200);
          margin-left: 11px;
        }
      }
      .button.add-user {
        background: var(--purple-200);
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 24px;
          letter-spacing: -0.01em;
          color: var(--light-100);
          white-space: nowrap;
        }
      }
    }
  }
  &__subtitle {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: var(--dark-100);
    padding-left: 32px;
    position: relative;
    .text:first-child {
      &:after {
        position: absolute;
        top: 10px;
        left: 0px;
        content: '';
        width: 16px;
        height: 32px;
        background-color: #CABDFF;
        border-radius: 4px;
      }
    }
    .usage-balance {
      &:after {
        display: none;
      }
    }
  }
  &__content {
    margin-top: 20px;
    .usage-balance {
      // display: flex;
      align-items: center;
      background: #F4F4F4;
      border: 1px solid #E2E2E2;
      border-radius: 8px;
      padding: 10px 12px;
      margin-left: 20px;
      img {
        width: 24px;
        height: 24px;
        margin-bottom: -4px;
      }
      .key {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #757575;
        margin-left: 10px;
        margin-right: 6px;
      }
      .value {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #2C3E50;
      }
    }
    .white-box:last-child {
      margin-top: 20px;
      .expenses__content-header {
        
        .expenses__subtitle {
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          color: var(--dark-100);
          padding-left: 32px;
          position: relative;
          &:after {
            position: absolute;
            top: 0px;
            left: 0px;
            content: '';
            width: 16px;
            height: 32px;
            background-color: #B5E4CA;
            border-radius: 4px;
          }
        }
      }
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 940px) {
  .expenses {
    &__content {
      .white-box {
        .home__char {
          overflow-x: scroll;
          .recharts-responsive-container {
            width: 862px !important;
            overflow-x: scroll;
          }
        }
      }
    }
  }
}
@media (max-width: 902px) {
  .expenses {
    &__content {
      .white-box:first-child {
        .home__analytics {
          .home__analytic.active {
            .left {
              .home__analytic-icon {
                display: none;
              }
            }
          }
          .home__analytic {
            .left {
              .home__analytic-icon {
                display: none;
              }
            }
          }
        }
        .expenses__content-header {
          flex-direction: column;
          align-items: unset;
          .expenses__subtitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text:first-child {
              &:after {
                position: absolute;
                top: 10px;
                left: 0px;
                content: '';
                width: 16px;
                height: 32px;
                background-color: #CABDFF;
                border-radius: 4px;
              }
            }
          }
          .expenses__time-select {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
@media(max-width: 910px) {
  .expenses {
    .main__header {
      flex-direction: column;
      align-items: start;
      &-buttons {
        .title {
          font-size: 24px;
        }
        .button:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
@media(max-width: 767px) {
  .expenses {
    .main__header {
      .title {
        font-size: 26px;
        margin-bottom: 10px;
      }
    }
    &__content {
      .white-box:last-child {
        margin-bottom: 75px !important;
      }
    }
    &__subtitle {
      font-size: 18px;
      white-space: nowrap;
    }
    .white-box:last-child {
      .expenses__content-header {
        .expenses__subtitle {
          font-size: 18px;

        }
      }
    }
  }
}
@media(max-width: 520px) {
  .expenses {
    &__content {
      .white-box:first-child {
        .expenses__content-header {
          .expenses__subtitle {
            align-items: unset;
            flex-direction: column;
            padding-left: 0;

            .text:first-child {
              margin-left: 25px;
              &:after {
                position: absolute;
                top: 0px;
                left: 0px;
                content: '';
                width: 16px;
                height: 32px;
                background-color: #CABDFF;
                border-radius: 4px;
              }
            }
            .usage-balance {
              margin-top: 15px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
@media(max-width: 450px) {
  .expenses {
    .main__header {
      &-buttons {
        .button {
          padding: 10px 10px;
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
@media(max-width: 400px) {
  .expenses {
    &__content {
      .white-box:last-child {
        margin-bottom: 75px !important;
      }
    }
    &__subtitle {
      font-size: 14px;
      white-space: nowrap;
    }
    .white-box:last-child {
      .expenses__content-header {
        .expenses__subtitle {
          font-size: 14px;
        }
      }
    }
  }
}