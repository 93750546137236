.faq {
  &__subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #1A1D1F;
    position: relative;
    padding-left: 32px;
    margin-bottom: 40px;
    &:after {
      position: absolute;
      top: 0px;
      left: 0px;
      content: '';
      width: 16px;
      height: 32px;
      border-radius: 4px;
      background: var(--blue-100);
    }
  }
  .feedback__subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #1A1D1F;
    position: relative;
    padding-left: 32px;
    margin-bottom: 40px;
    margin-top: 40px;
    &:after {
      position: absolute;
      top: 0px;
      left: 0px;
      content: '';
      width: 16px;
      height: 32px;
      border-radius: 4px;
      background: #FFBC99;
    }
  }
}
@media (max-width: 450px) {
  .faq {
    &__subtitle {
      margin-bottom: 20px;
    }
    .feedback__subtitle {
      margin-bottom: 20px;
    }
  }
}