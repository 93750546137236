.card-stripe {
    .FormGroup {
      padding: 13px 2px;
      background-color: white;
      border-radius: 4px;
      &:first-child {
        padding: 0px;
      }
    }
  }
.payment-info {
    &__title {
      font-weight: 600;
      font-size: 48px;
      line-height: 48px;
      letter-spacing: -0.03em;
      color: var(--dark-100);
      margin-bottom: 34px;
    }
}
  .Form {
    border-radius: 5px;
    padding: 24px;
    box-sizing: border-box;
    background: #F4F4F4;
    border-radius: 12px;
    width: 100%;
  }
  .FormGroup {
    border: none;
    padding: 0px;
  }
  .FormRow {
    display: flex;
    align-items: center;
    width: 100%;
    iframe {
      height: 50px !important;
      background: #e7e7e7;
      //-moz-border-radius-bottomleft: 12px !important;
      border-bottom-right-radius: 12px !important;
      border-top-right-radius: 12px !important;
      padding: 4px;
      width: 100%;

      .CardField--ltr .CardBrandIcon-container {
        left: 6px;
      }
    }
  }
  .substrate-icon {
    width: 10px;
    height: 50px;
    background-color: #e7e7e7;
    border-bottom-left-radius: 12px !important;
    border-top-left-radius: 12px !important;
  }

  input {
    &#name {
      background: #ffffff;
      mix-blend-mode: normal;
      border: 1px solid #e4e5e9;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #092742;
      padding-left: 18px;
      padding-top: 13px;
      padding-bottom: 13px;
      width: 100%;
      outline: none;

      &:focus {
        outline: none;
      }
    }
  }

  .CardNumberField-input-wrapper {
    .input {
      background: #ffffff;
      mix-blend-mode: normal;
      border: 1px solid #e4e5e9;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #092742;
      padding-left: 18px;
      padding-top: 13px;
      padding-bottom: 13px;
      width: 100%;
      outline: none;
      &:focus {
        outline: none;
      }
    }
  }
  .CardField--ltr .CardBrandIcon-container {
    .CardField-input-wrapper .is-ready-to-slide {
      margin-top: 100px !important;
      display: flex !important;
      flex-direction: column !important;
    }
  }
  .CardField.CardField--ltr {
    .CardBrandIcon-container {
      left: 5px !important;
    }
  }
.CardBrandIcon-inner {
  left: 6px;
}
.StripeElement {
  width: 100%;
   #document {
     .ElementsApp {
       position: absolute;
       height: 100%;
       top: 0;
       left: 5px;
       width: 100%;
       z-index: 1;
       .CardField.CardField--ltr {
         .CardBrandIcon-container {
           left: 5px !important;
         }
       }
     }
   }
}

  .SubmitButton {
    width: 100%;
    background: #4BBC7B;
    border-radius: 5px;
    margin-top: 20px;
    font-weight: bold;
    font-size: 12px;
    border: none!important;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;
    margin-top: 34px;
  }

  .InputContainer .InputElement {
    font-size: 25px !important;

  }


  .ElementsApp input {
    font-size: 25px !important;
  }