.google__button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FCFCFC;
  border: 2px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  padding: 6px 26px;
  transition: all 0.3s;
  &:hover {
    transform: translateY(-3px);
  }
  &:active {
    transform: translateY(0px);
  }
  .img {

  }
  .text {
    margin-left: 8px;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: var(--dark-100);
  }
}
