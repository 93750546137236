.home {
  &__char {
    margin-top: 32px;
  }
  
}

.graph-content {
  background: #272B30;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000;
  border-radius: 8px;
  padding: 8px;
  min-width: 83px;
}
.graph-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.01em;
  color: #EFEFEF;
  margin-bottom: 8px;
}
.graph-value {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.01em;
  color: #FCFCFC;
  position: relative;
  padding-left: 16px;
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 12px;
    height: 12px;
    background: #CABDFF;
    border-radius: 4px;
  }
}