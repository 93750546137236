.user-details {
    &__title {
        font-weight: 600;
        font-size: 32px;
        line-height: 50px;
        color: #2C3E50;
        margin-bottom: 24px;
    }
    &__header {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }
    &__data {
        background: #FCFCFC;
        border-radius: 16px;
        padding: 16px;
        box-sizing: border-box;
        min-width: 268px;
    }
    &__subtitle {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #2C3E50;
        min-height: 32px;
        position: relative;
        padding-left: 32px;
        &:after {
            content: "";
            position: absolute;
            border-radius: 4px;
            top: 0px;
            left: 0px;
            width: 16px;
            height: 100%;
        }
        &.purple {
            &:after {
                background: #CABDFF;
            }
        }
        &.blue {
            &:after {
                background: #B1E5FC;
            }
        }
    }
    &__avatar {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 72px;
            height: 72px;
            border-radius: 50%;
        }
    }
    &__fullname {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #2C3E50;
        text-align: center;
        margin-top: 12px;
    }
    &__email {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #757575;
        text-align: center;
        margin-top: 8px;
    }
    &__id {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #2C3E50;
        margin-top: 8px;
    }
    &__info {
        padding: 16px;
        background: #FCFCFC;
        border-radius: 16px;
        width: 100%;
        margin-left: 16px;
    }
    &__boxs {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin-top: 32px;
    }
    &__box {
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
        border-radius: 12px;
        padding: 16px;
        max-width: 100%;
        width: 100%;
        margin-right: 12px;
        &:last-child {
            margin-right: 0px;
        }
        .box {
            &__img {
                margin-bottom: 16px;
                img {
                    width: 40px;
                    height: 40px;
                }
            }
            &__title {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                color: #757575;
                margin-bottom: 8px;
            }
            &__text {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                color: #2C3E50;
            }
        }
    }
    &__table {
        padding: 16px;
        background: #FCFCFC;
        border-radius: 16px;
        margin-top: 24px;
        .table {
            &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 42px;
            }
            &__title {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #2C3E50;
                position: relative;
                padding-left: 32px;
                min-height: 32px;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 16px;
                    height: 32px;
                    background: #B5E4CA;
                    border-radius: 4px;
                }
            }
            &__tr {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px;
                border-bottom: 1px solid #EFEFEF;
                &:last-child {
                    border: none;
                }
            }
            &__subtitle {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #85868C;
                &.address {
                    max-width: 19%;
                    width: 100%;
                    margin-right: 10px;
                }
                &.date {
                    max-width: 11%;
                    width: 100%;
                }
                &.time {
                    max-width: 19%;
                    width: 100%;
                }
            }
            &__value {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #2C3E50;
                &.address {
                    max-width:  30%;
                    width: 100%;
                }
                &.date {
                    max-width: 21%;
                    width: 100%;
                }
                &.time {
                    max-width: 19%;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .user-details {
        &__data {
            min-width: 200px;
        }
        .box__text,
        .box__title {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 3px;
        }
    }
}

@media (max-width: 768px) {
    .user-details {
        margin-bottom: 70px;
        &__header {
            flex-direction: column;
        }
        &__data {
            margin-right: 0px;
        }
        &__info {
            margin-left: 0px;
            margin-top: 16px;
        }
    }
}

@media (max-width: 580px) {
    .user-details {
        &__boxs {
            flex-direction: column;
        }
        &__box {
            background-color: transparent;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 0px;
            .texts {
                order: -1;
            }
            border-bottom: 1px solid #EFEFEF;
            border-radius: 0px;
            &:last-child {
                border: none
            }
        }
        &__table {
            .th {
                display: none;
            }
            .table__tr {
                flex-direction: column;
            }
            .table__value {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                width: 100%;
                max-width: 100% !important;
                margin-bottom: 33px;
                &::before {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    color: #6F767E;
                }

                &.address {
                    display: flex;
                    &::before {
                        content: 'Address';
                    }
                }
                &.date {
                    &::before {
                        content: "Date";
                    }
                }
                &.time {
                    &::before {
                        content: "Time";
                    }
                }
            }
        }
    }
}

@media (max-width: 544px) {
    .user-details {
        &__boxs {
            flex-direction: column;
        }
        &__box {
            background-color: transparent;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 0px;
            .texts {
                order: -1;
            }
            border-bottom: 1px solid #EFEFEF;
            border-radius: 0px;
            &:last-child {
                border: none
            }
        }
        &__table {
            .th {
                display: none;
            }
            .table__tr {
                flex-direction: column;
            }
            .table__value {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                width: 100%;
                max-width: 100% !important;
                margin-bottom: 33px;
                &::before {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    color: #6F767E;
                }

                &.address {
                    display: flex;
                    &::before {
                        content: 'Address';
                        margin-right: 20px;
                    }
                }
                &.date {
                    &::before {
                        content: "Date";
                    }
                }
                &.time {
                    &::before {
                        content: "Time";
                    }
                }
            }
        }
    }
}