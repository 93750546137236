.expenses {
  &__table {
    background: #FCFCFC;
    border-radius: 8px;
    margin-top: 24px;
    &-header {
      width: 100%;
      display: flex;
      .expenses__table-th.date {
        width: 30%;
      }
      .expenses__table-th.type {
        width: 25%;
        display: flex;
        align-items: center;
        .text {
          margin-right: 8px;
        }
      }
      .expenses__table-th.data {
        width: 25%;
        display: flex;
        align-items: center;
        .text {
          margin-right: 8px;
        }
      }
      .expenses__table-th.amount {
        width: 20%;
        display: flex;
        align-items: center;
        .text {
          margin-right: 8px;
        }
      }
    }
    &-hr {
      width: 100%;
      height: 1px;
      background: var(--gray-300);
      margin-top: 18px;
    }
    &-row {
      display: flex;
      align-items: center;
      padding: 16px 0px;
      .subtitle-mobile {
        display: flex;
        white-space: nowrap;
        span {
          display: none;
        }
        .type {
          .span {
            display: block;
          }
        }
      }
      .subtitle-mobile.type {
        width: 25%;
      }
      .subtitle-mobile.address {
        width: 29%;
      }
      .subtitle-mobile.data {
        width: 24%;
      }
      .subtitle-mobile.amount {
        width: 20%;
        padding-left: 6px;
      }

      .expenses__table-td.type {
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }

    }
    &-th {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: -0.01em;
      color: var(--gray-400);

    }
    &-td {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: var(--dark-300);
      &.status {
        border-radius: 6px;
        max-width: max-content;
        padding: 2px 8px;
        &.pending {
          color: var(--orange-200);
          background: rgba(255, 149, 0, 0.1);
        }
        &.paid {
          color: #16A085;
          background: rgba(22, 160, 133, 0.1);
        }
      }
      &.fees {
        color: #8E8E93;
        &.danger {
          color: #E74C3C;
        }
      }
    }
   &-content {
     .item {
       .expenses__table-hr:last-child {
          display: none;
       }
     }
   }
  }
}
@media (max-width: 1060px) {
  .expenses {
    &__table {
      &-header {
        .expenses__table-th.date {
          width: 25%;
        }
        .expenses__table-th.amount {
          width: 16%;
        }
      }
      &-row {
        .subtitle-mobile.address {
          width: 23%;
          //margin-right: 20px;
        }
        .subtitle-mobile.amount {
          padding-left: 0px;
          width: 16%;
        }
        .subtitle-mobile.data {
          margin-right: 10px;
        }
        .subtitle-mobile.type {
          margin-right: 10px;
        }
      }
    }
  }
}
@media (max-width: 963px) {
  .expenses {
      &__table {
        &-content {
          .item {
            .expenses__table-hr:first-child {
              display: none;
            }
            .expenses__table-hr:last-child {
              display: block;
              margin-top: 0;
            }
          }
        }
        &-header {
          display: none;
        }
        &-row {
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .expenses__table-td.address {
            max-width: max-content;
          }
          .expenses__table-td.type {
            max-width: max-content;
            display: flex;
            align-items: center;
            img {
              display: none;
            }
          }
          .expenses__table-td.data {
            max-width: max-content;
          }
          .subtitle-mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 12px;
            span {
              display: block;
            }
          }
          .subtitle-mobile.address {
            width: 100%;
            justify-content: space-between;
          }
          .subtitle-mobile.type {
            width: 100%;
            justify-content: space-between;
            margin-right: 0;
          }
          .subtitle-mobile.data {
            width: 100%;
            justify-content: space-between;
            margin-right: 0;
          }
          .subtitle-mobile.amount {
            width: 100%;
            justify-content: space-between;
          }
        }
      }
  }
}
@media (max-width: 375px) {
  .expenses {
    &__table {
      //margin-bottom: 60px;
    }
  }
}