.payment {
    &__box {
      .subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: var(--gray-500);
        margin-top: 38px;
      }
    }
    &__body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      .user-name {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: var(--dark-300);
      }
      .card {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: var(--dark-300);
        margin-top: 8px;
      }
      .subscription {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: var(--dark-300);
        a {
          text-decoration: none;
          color: #2A85FF;
        }
      }
      .billing-date {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray-500);
        margin-top: 8px;
      }
    }
    &__delete {
      cursor: pointer;
      background: var(--light-100);
      border: 2px solid var(--gray-300);
      box-sizing: border-box;
      border-radius: 12px;
      padding: 12px 22px;
      display: flex;
      align-items: center;
      transition: all .3s;
      &:hover {
        transform: translateY(-3px);
      }
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: var(--dark-100);
      }
    }
}
@media(max-width: 1333px) {
  .payment {
    &__body {
      flex-direction: column;
      align-items: start;
      .subscription {
        margin-top: 24px;
      }
    }
    &__delete {
      margin-top: 32px;
    }
  }
}