.security {
  &__form {
    margin-top: 34px;
  }
  &__field {
    .label {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: #33383F;
      margin-bottom: 12px;
      svg {
        margin-top: 8px;
        margin-left: 5.5px;
        cursor: pointer;
      }
    }
  }
  &__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: space-between;
    margin-top: 13px;
    .security__field {
      width: 100%;
    }
    .security__field:last-child {
      margin-top: 13px;
    }
  }
  &__button {
    max-width: 168px;
    margin-top: 48px;
  }
}
