.signup {
    &__details {
        max-width: 284px;
        width: 100%;
        margin: 0px auto;
        &-form {
            margin-top: 32px;
        }
        &-input {
            margin-top: 12px;
        }
        &-button {
            margin-top: 20px;
        }
    }
}