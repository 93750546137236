.signup__subscription {
  max-width: 406px;
  width: 100%;
  margin: 0 auto;
  .signup__description {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #2C3E50;
    margin-bottom: 8px;
  }
  &-subtitle {
    display: flex;
    align-items: center;
    .price {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: right;
      color: var(--dark-300);
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 50px;
        color: var(--dark-300);
      }
    }
    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-500);
      margin-left: 4px;
    }
  }
  &-hint {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #757575;
    background: #F4F4F4;
    border-radius: 8px;
    padding: 10px;
    margin-top: 12px;
  }
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #757575;
    margin-top: 12px;
    margin-bottom: 32px;
  }
  &-total {
    background: #F4F4F4;
    border-radius: 8px;
    padding: 23.5px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-totalText {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2C3E50;
  }
  &-btn {
    margin-top: 24px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    .count {
      display: flex;
      align-items: center;
      height: 40px;
      width: 100%;
      &__block {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 63px;
        width: 100%;
        border-bottom: 1px solid #2C3E50;
        border-top: 1px solid #2C3E50;
        height: 100%;
      }
      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 41px;
        width: 100%;
        border: 1px solid #2C3E50;
        height: 100%;
        cursor: pointer;
        &:last-child{
          border-radius: 0px 12px 12px 0px;
        }
        &:first-child{
          border-radius: 12px 0px 0px 12px;
        }
      }
    }
  }
}

.width_111 {
  max-width: 111px;
  width: 100%;
}
@media(max-width: 600px) {
  .signup__subscription {
    &-hint {
      font-size: 14px;
    }
    .signup__title {
      font-size: 32px;
    }
    .signup__description {
      margin-top: 24px;
    }
  }
}