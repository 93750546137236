
.usage-history {
    margin-bottom: 100px;
    &__content {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .home__subtitle {
        white-space: nowrap;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: var(--dark-100);
        padding-left: 32px;
        position: relative;
        &:after {
            background-color: #B5E4CA;
          }
    }
    &__table {
        margin-top: 26px;
        margin-bottom: 40px;

    }
    &__th {
        display: grid;
        grid-template-columns: 0.7fr 2fr 1fr 1fr;
        grid-gap: 3px;
        border-bottom: 1px solid #EFEFEF;
        padding: 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #757575;
    }
    &__td {
        display: grid;
        grid-template-columns: 0.7fr 2fr 1fr 1fr;
        // background: #F4F4F4;
        border-radius: 8px;
        margin-top: 12px;
        padding: 24px 12px;
        grid-gap: 3px;

        .type,
        .date,
        .time,
        .name {
            display: flex;
            align-items: flex-start;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #2C3E50;
        }
    }
}

@media (max-width: 1000px) {
    .usage-history {
        &__th {
            display: none;
        }
        &__td {
            display: flex;
            flex-direction: column;
            padding-top: 0px;
            padding-bottom: 0px;
            background: #F4F4F4;
            .name,
            .type,
            .date,
            .time {
                border-bottom: 1px solid #fff;
                padding-bottom: 10px;
                padding-top: 10px;
                &::before {
                    content: 'Name:';
                    margin-right: 10px;
                }
            }
            .type {
                &::before {
                    content: 'Address:';
                }
            }
            .date {
                &::before {
                    content: 'Date:';
                }
            }
            .time {
                &::before {
                    content: 'Time:';
                }
            }
        }
    }
}


@media (max-width: 500px) {
    .usage-history {
        .home__subtitle {
            font-size: 16px;
        }
    }
}