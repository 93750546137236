.switch {
    position: relative;
    input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
        position: absolute;
        top: 0px;
        left: 0px;
    }
    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 48px;
        height: 24px;
        background: #F4F4F4;
        display: block;
        border-radius: 32px;
        position: relative;
        transition: all 0.3s;
        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 20px;
            height: 20px;
            background: #FCFCFC;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), inset 0px 2px 2px #FFFFFF, inset 0px -1px 1px rgba(0, 0, 0, 0.1);
            border-radius: 24px;
            transition: 0.3s;
        }
    }
    input:checked + label {
        background: #2A85FF;
    }
    input:checked + label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }
    label:active:after {
        width: 22px;
    }
}