.home__grid {
    &-header,
    &-row {
        display: grid;
        grid-template-columns:  1.5fr 1.5fr 1fr 1fr 0.9fr 1fr;
        grid-gap: 10px;
    }
    &-header {
        padding-top: 15px;
        padding-bottom: 19px;
        border-bottom: 1px solid #EFEFEF;
        margin-bottom: 12px;
        margin-top: 16px;
    }
    &-row {
        border-bottom: 1px solid #EFEFEF;
        padding-top: 12px;
        padding-bottom: 12px;
        cursor: pointer;
        .name {
            word-break: break-all;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #2C3E50;
        }
        .phone,
        .id,
        .visits {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #2C3E50;
        }
        .status {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            border-radius: 6px;
            width: max-content;
            padding: 2px 8px;
            text-transform: capitalize;

            &.new {
                color: #4BBC7B;
                background: #E8F7EC;
            }
            &.pending {
                color: #0A84FF;
                background: #D4EDF8;
            }
            &.activated {
                color: #5856D6;
                background: #E6E0FF;
            }
            &.deactivated {
                color: rgba(60, 60, 67, 0.6);
                background: #F2F2F7;
            }
        }
        .actions {
            display: flex;
            justify-content: center;
            .delete {
                cursor: pointer;
            }
            .edit {
                display: inline-block;
                cursor: pointer;
                margin-right: 10px;
            }
        }
    }
    .grid__th {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #85868C;
    }
}

@media (max-width: 1100px) {
    .home__grid {
        &-header {
            display: none;
        }
        &-row {
            grid-template-columns: 1fr;
            .name,
            .phone,
            .id,
            .status,
            .visits {
                position: relative;
                padding-left: 60px;
                &::before {
                    content: 'User:';
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #85868C;
                }
            }
            .phone {
                padding-left: 120px;
                &::before {
                    content: 'Phone number:';
                }
            }
            .id {
                padding-left: 92px;
                &::before {
                    content: 'ID number:';
                }
            }
            .status {
                padding-left: 62px;
                &::before {
                    content: 'Status:';
                }
            }
            .visits {
                padding-left: 92px;
                &::before {
                    content: 'User visits:';
                }
            }
            .actions {
                justify-content: flex-end;
            }
        }
    }
}