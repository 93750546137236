.userPopup {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 555;
  display: flex;
  align-items: center;
  justify-content: center;
  &__checkbox {
    display: flex;
    align-items: center;
    margin-top: 24px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #2C3E50;
      margin-left: 10px;
    }
  }
  &__text {
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: var( --dark-300);
      margin-bottom: 24px;
    }

    &-hr {
      width: 100%;
      height: 1px;
      background: #E9E9E9;
    }
  }
  &__content {
    max-width: 664px;
    width: 100%;
    background: #FCFCFC;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(32px);
    border-radius: 16px;
    position: relative;
    z-index: 11;
    padding: 36px;
  }
  &__field {
    position: relative;
    margin-top: 34px;
    &:first-child {
      margin-top: 0;
    }
    .number {
      position: absolute;
      top: 52px;
      left: 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: #95A5A6;
      &_black {
        color: #33383F;
        &-error {
          color: var(--danger-200);
          left: 41px;
        }
      }
    }
    input:last-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.01em;
    }
  }
  &__label {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #33383F;
    margin-bottom: 14px;
    position: relative;
    svg {
      margin-top: 6px;
      margin-left: 5.5px;
      cursor: pointer;
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 49px;
  }
  &__btn {
    width: 100%;
    margin-right: 8px;
    &:last-child {
      margin-right: 0px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
    &-left {
      display: flex;
      align-items: center;
    }
    &-block {
      width: 16px;
      height: 32px;
      background: #CABDFF;
      border-radius: 4px;
      margin-right: 16px;
    }
    &-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #2C3E50;
    }
    &-right {
      width: 36px;
      height: 36px;
      background: #EFEFEF;
      border-radius: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  &__bg {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
}
@media (max-width: 450px) {
  .userPopup {
    height: 88vh;
  }
}