.checkbox {
    position: relative;
    width: 24px;
    height: 24px;
    label {
        display: block;
        position: relative;
        width: 24px;
        height: 24px;
        border: 2px solid #95A5A6;
        box-sizing: border-box;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s;
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
            background-image: url('./checkbox-arrow.svg');
            width: 17px;
            height: 11px;
        }
    }
    input:checked + label {
        background: #2A85FF;
        border-color: #2A85FF;
    }
    input {
        position: absolute;
        top: -100px;
        left: -100px;
        z-index: -100;
        opacity: 0;
    }
}