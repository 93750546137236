.signup {
    &__password {
        max-width: 412px;
        width: 100%;
        margin: 0px auto;
        &-form {
            margin-top: 32px;
        }
        &-input {
            margin-top: 12px;
        }
        &-button {
            margin-top: 20px;
        }
        &-subtitle {
            margin-top: 20px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: var(--dark-100);
        }
        &-info {
            display: flex;
            align-items: center;
            margin-top: 8px;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: var(--dark-100);
            img {
                margin-right: 8px;
            }
        }
    }
}
@media (max-width: 455px) {
    .signup {
        &__password {
            max-width: 300px;
        }
    }
}