.account {
  &__form {
    margin-top: 34px;
    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: #33383F;
      margin-bottom: 14px;
      svg {
        margin-top: 8px;
        margin-left: 5.5px;
        cursor: pointer;
      }
      .change-email {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: underline;
        cursor: pointer;
        color: #2A85FF;
      }
    }
  }
  &__field {
    margin-top: 34px;
    &:first-child {
      margin-top: 0px;
    }
  }
  &__button {
    margin-top: 48px;
    max-width: 76px;
  }
  &__photo {
    display: flex;
    align-items: center;
    .photo {
      margin-right: 16px;
      cursor: pointer;
      img {
        border-radius: 55.1724px;
        width: 96px;
        height: 96px;
        object-fit: cover;
      }
    }
    .remove__photo {
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: #2C3E50;
      padding: 12px 20px;
      background: #FCFCFC;
      border: 2px solid #EFEFEF;
      border-radius: 12px;
      cursor: pointer;
      transition: all 0.3s;
        &:hover {
          transform: translateY(-3px);
        }
        &:active {
          transform: translateY(0px);
        }
    }
    .upload__photo {
      margin-right: 12px;
      input {
        display: none;
      }
      .upload__button {
        display: inline-block;
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #FCFCFC;
        padding: 12px 20px;
        background: #2A85FF;
        border-radius: 12px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          transform: translateY(-3px);
        }
        &:active {
          transform: translateY(0px);
        }
      }
    }
  }
  
  
}
@media(max-width:550px) {
    .account {
      &__photo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .photo {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }
}
@media(max-width:388px) {
  .account {
    &__photo {
      .upload__photo .upload__button {
        padding: 6px 12px;
      }
      .remove__photo {
        padding: 6px 12px;
      }
    }
  }
}
@media(max-width:335px) {
  .account {
    &__photo {
      .upload__photo .upload__button {
        margin-bottom: 10px;
      }
    }
  }
}