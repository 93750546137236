.home {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__subtitle {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: var(--dark-100);
        padding-left: 32px;
        position: relative;
        &:after {
          position: absolute;
          top: 0px;
          left: 0px;
          content: '';
          width: 16px;
          height: 32px;
          background-color: var(--orange-100);
          border-radius: 4px;
        }
      }
    &__content {
        margin-top: 24px;
        .white-box:first-child {
            margin-bottom: 20px;
            .home__subtitle {
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                letter-spacing: -0.02em;
                color: #1A1D1F;
                position: relative;
                padding-left: 32px;
                &:after {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    content: '';
                    width: 16px;
                    height: 32px;
                    border-radius: 4px;
                    background: var(--orange-100);
                }
            }
        }
        .white-box:last-child {
            .home__subtitle {
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                letter-spacing: -0.02em;
                color: #1A1D1F;
                position: relative;
                padding-left: 32px;
                &:after {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    content: '';
                    width: 16px;
                    height: 32px;
                    border-radius: 4px;
                    background: var(--blue-100);
                }
            }
        }
    }
    &__table {
        .table {
            &__tr {
                display: flex;
                align-items: center;
                // justify-content: space-between;
                border-bottom: 1px solid #EFEFEF;
                padding-top: 15px;
                padding-bottom: 15px;
                width: 100%;
                .table__th.status {
                    width: 14%;
                }
                .subscription {
                    display: none;
                }
                &.user-row {
                    cursor: pointer;
                }
                .table__header {
                    width: 20%;
                    display: flex;
                    margin-right: 10px;
                    .table__th {
                        &.user {
                            max-width: 200px;
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            word-break:break-all;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 22px;
                            color: #2C3E50;
                            white-space: inherit;
                        }
                    }
                }
                .table__body {
                    width: 75%;
                    display: flex;
                    justify-content: space-between;
                    &-hr {
                        display: none;
                    }
                    .table__column.visits {
                        max-width: 14%;
                        width: 100%;
                    }
                    .table__column.phone {
                        max-width: 24%;
                        width: 100%;
                    }
                    .table__column.id {
                        max-width: 16%;
                        width: 100%;
                    }
                    .table__visit {
                        width: 16%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .table__td.subscription {
                            white-space: nowrap;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 18px;
                            color: var(--gray-500);
                            margin-right: 20px;
                        }
                        .table__td.visit {
                            max-width: 100px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: #FF9500;
                            text-align: center;
                            white-space: nowrap;
                            background: #FFF4E5;
                            border-radius: 6px;
                            padding: 2px 8px;
                            &.active {
                                color: #4BBC7B;
                                background: #E8F7EC;
                            }
                        }
                    }
                    .table__user-visits {
                        max-width: 0%;
                        width: 100%;
                    }
                }
                 &:last-child {
                    border: none;
                }
                .table__btn {
                    display: flex;
                    justify-content: end;
                    max-width: 160px;
                    width: 100%;
                    .edit {
                        cursor: pointer;
                        margin-right: 10px;
                    }
                    .delete {
                        cursor: pointer;
                    }

                }
            }

            &__th {
                width: 14%;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #85868C;
                
                &.table__checkbox {
                    width: 24px;
                    margin-right: 32px;
                }
                &.user {
                    max-width: 21%;
                    width: 100%;
                }
                &.phone {
                    max-width: 22%;
                    width: 100%;
                }
                &.id {
                    max-width: 20%;
                    width: 100%;
                }
            }
            &__td {
                &.table__checkbox {
                    width: 24px;
                    margin-right: 32px;
                }
                &.user,
                &.phone,
                &.id {
                    max-width: 100%;
                    width: 100%;
                    white-space: nowrap;
                }
                &.user {
                    max-width: 200px;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    word-break:break-all;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #2C3E50;
                    white-space: inherit;
                    margin-right: 15px;
                }
                &.id,
                &.phone {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #2C3E50;
                }
            }
        }
    }
    .main__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-buttons {
            display: flex;
            .button:first-child {

            }
            .button {
                background: var(--light-100);
                border-radius: 8px;
                padding: 12px 20px;
                margin-left: 16px;
                span {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    color: var(--dark-200);
                    margin-left: 11px;
                }
            }
            .button.add-user {
                background: var(--purple-200);
                span {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    color: var(--light-100);
                    white-space: nowrap;
                }
            }
        }
    }
    &__import {
        display: none;
    }
    &__list {
        &-none {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: 40px;
            padding-bottom: 40px;
            img {
                max-width: 248px;
                width: 100%;
            }
            p {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                color: #85868C;
                margin-top: 10px;
            }
            button {
                max-width: 174px;
                margin-top: 10px;
            }
        }
    }
    .modal {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        z-index: 999;
        .button {
            background: var(--purple-100);
            border-radius: 8px;
            padding: 12px 20px;
            margin-top: 20px;
            span {
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 24px;
                letter-spacing: -0.01em;
                color: var(--light-200);
                margin-left: 11px;
            }
        }
        .example {
            font-weight: bold;
            margin-top: 5px;
            border: 2px solid var(--purple-100);
            width: max-content;
            padding: 10px;
            border-radius: 2px;
        }
        &__bg {
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: rgba(0, 0, 0, 0.664);
        }
        &__content {
            background-color: #fff;
            height: max-content;
            padding: 16px;
            margin-top: 50px;
            border-radius: 9px;
            .title {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #2C3E50;
            }
            .description {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #6E7A80;
                margin-top: 24px;
            }
            .img {
                margin-top: 24px;
            }
        }
    }
}
@media(max-width: 1680px) {
    .home__table .table__tr .table__body .table__column.visits {
        max-width: 10%;
        width: 100%;
    }
}
@media(max-width: 1500px) {
    .home__table .table__tr .table__body .table__visit {
        width: 16%;
    }
    .home__table .table__tr .table__body .table__column.visits {
        max-width: 7%;
        width: 100%;
    }
}
@media(max-width: 1320px) {
    .home__table .table__tr .table__btn {
        max-width: 122px;
        width: 100%;
    }
}
@media(max-width: 1060px) {
    .home {
        &__table {
            .table__tr {
                .table__btn {
                    max-width: 134px;
                }
            }
        }
    }
}
@media(max-width: 1080px) {
    .home {
        &__content {

        }
        .home__table .table__tr.user-row {
            position: relative;
        }
        .home__table .table__tr .table__btn {
            position: absolute;
            bottom: 10px;
            right: 0;
        }
        &__table {

            .table {
                &__tr:first-child {
                    display: none;
                }
                &__tr {
                    flex-direction: column;
                    align-items: start;
                    padding-bottom: 20px;
                    border-bottom: 2px solid #F4F4F4;
                    .table__header {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        .user {
                            white-space: nowrap;
                            order: 1;
                            max-width: 100%;
                        }
                        .table__checkbox {
                            order: 2;
                        }
                    }
                    .table__body {
                        width: 100%;
                        flex-direction: column;
                        margin-top: 22px;
                        &-hr {
                            display: block;
                            width: 100%;
                            height: 1px;
                            background: #EFEFEF;
                            border-radius: 1px;
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }

                        .table__column {
                            max-width: 200px;
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .subscription {
                                white-space: nowrap;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 18px;
                                color: var(--gray-500);
                                margin-right: 20px;
                            }
                        }
                    }
                    .subscription {
                        display: block;
                    }
                }
                &__tr.user-row {
                    .table__hr {
                        width: 100%;
                        height: 2px;
                        background: red;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                }
            }
            .table__td {

            }
        }
    }
}

@media(max-width: 846px) {
    .home {
        .main__header {
            flex-direction: column;
            align-items: start;
            &-buttons {
                .button:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .home {
        &__content {
            .white-box:last-child {
                margin-bottom: 60px;
            }
        }
    }
}
@media(max-width: 580px) {
    .home {
        &__content {

        }
        &__table {
            .table {
                &__tr:first-child {
                    display: none;
                }
                &__tr {
                    flex-direction: column;
                    align-items: start;
                    .table__header {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        .user {
                            white-space: nowrap;
                            order: 1;
                            max-width: 100%;
                        }
                        .table__checkbox {
                            order: 2;
                        }
                    }
                    .table__body {
                        flex-direction: column;
                        margin-top: 22px;
                        .table__column {
                            max-width: 200px;
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 16px;
                            .subscription {
                                white-space: nowrap;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 18px;
                                color: var(--gray-500);
                                margin-right: 20px;
                            }
                        }
                    }
                    .subscription {
                        display: block;
                    }
                }
            }
            .table__td {

            }
        }
    }
}
@media(max-width: 450px) {
    .home {
        .main__header {
            &-buttons {
                margin: 0 auto;
                .button {
                    padding: 10px 10px;
                    span {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
@media(max-width: 370px) {
    .home {
        .main__header {
            &-buttons {
                .button {
                    padding: 8px 8px;
                }
            }
        }
    }
}
