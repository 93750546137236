.signup {
    &__email {
      max-width: 296px;
      width: 100%;
      margin: 0px auto;
    }
    &__field {
      margin-top: 20px;
    }
    &__input {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
    }
    &__email {
      &-button {
        margin-top: 12px;
      }
    }
    &__title {
      font-weight: 600;
      font-size: 48px;
      line-height: 48px;
      letter-spacing: -0.03em;
      color: var(--dark-100);
    }
    &__description {
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: var(--dark-100);
      margin-top: 32px;
      &.gray {
        color: var(--gray-200);
      }
    }
    &__line {
      width: 100%;
      height: 2px;
      background: var(--gray-300);
      border-radius: 2px;
      margin-top: 32px;
    }
    &__navigation {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 96px;
      padding-left: 27px;
      padding-right: 40px;
      .left {
        .logo {
          display: block;
        }
        .mobile-logo {
          display: none;
        }
      }
      .right {
        span {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: var(--gray-200);
          a {
            margin-left: 10px;
            color: var(--dark-100);
            text-decoration: none;
          }
        }
      }
    }
    &__content {
      display: flex;
      align-items: center;
      min-height: 100vh;
    }
    &__left {
      height: 100vh;
      max-width: 400px;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &__right {
      // max-width: 396px;
      width: 100%;
      margin: 0px auto;
    }
  }
  @media (max-width: 902px) {
    .signup {
      &__left {
        display: none;
      }
    }
  }
  @media (max-width: 455px) {
    .signup {
      &__subscription {
        padding: 5px;
        .payment-info__title {
          font-weight: 600;
          font-size: 32px;
          line-height: 50px;
        }
      }
      &__navigation {
        .left {
          .logo {
            display: none;
          }
          .mobile-logo {
            display: block;
          }
        }
      }
    }
  }
  @media(max-width: 410px) {
    .signup__subscription {
      padding: 10px;
    }
  }