.settings {
  &__main {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;
  }
  &__nav {
    display: flex;
    flex-direction: column;
    max-width: 210px;
    margin-right: 32px;
    width: 100%;
    &-link {
      width: 100%;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: #6F767E;
      text-decoration: none;
      padding: 8px 16px;
      background-color: transparent;
      transition: all 0.3s;
      &.active {
        color: #1A1D1F;
        background: #EFEFEF;
        border-radius: 8px;
      }
    }
  }
  &__content {
    width: 100%;
  }
  &__subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #1A1D1F;
    position: relative;
    padding-left: 32px;
    &:after {
      position: absolute;
      top: 0px;
      left: 0px;
      content: '';
      width: 16px;
      height: 32px;
      border-radius: 4px;
    }
  }
  .profile {
    .settings__subtitle {
      &::after {
        background-color: #B5E4CA;;
      }
    }
  }
  .security {
    .settings__subtitle {
      &::after {
        background-color: #CABDFF;
      }
    }
  }
  .payment {
    .settings__subtitle {
      &::after {
        background-color: #B5E4CA;;
      }
    }
  }

  .other {
    display: none;
    background-color: var(--light-100);
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    &__subtitle {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: #1A1D1F;
        position: relative;
        padding-left: 32px;
        &:after {
            position: absolute;
            top: 0px;
            left: 0px;
            content: '';
            width: 16px;
            height: 32px;
            border-radius: 4px;
            background: var(--orange-100);
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        .other__link {
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 12px;
            background-color: transparent;
            border-radius: 12px;
            margin-top: 42px;
            margin-bottom: 32px;
            &.active {
                background: var(--gray-300);
                box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF;
                .text {
                    color: var(--dark-300);
                }
            }
            .icon {
                margin-right: 12px;
            }
            .text {
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                color: var(--dark-200);
            }
        }
        .other__logout {
            display: flex;
            align-items: center;
            text-decoration: none;
            background: #FFFFFF;
            border: 1px solid #EFEFEF;
            box-sizing: border-box;
            border-radius: 12px;
            padding: 14px 15px;
            &.active {
                background: var(--gray-300);
                box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF;
                .text {
                    color: var(--red-100);
                }
            }
            svg {
                path {
                    fill: var(--red-100);
                }
            }
            .text {
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                color: var(--red-100);
                margin-left: 14px;
            }
        }
    }
}
}
@media (max-width: 1180px) {
  .settings {
    .security {
      &__row {
        width: 100%;
        flex-direction: column;
        .security__field {
          width: 100%;
          margin-bottom: 34px;
        }
      }
      &__button {
        margin-top: 0;
      }
    }
    &__nav {
      display: flex;
      flex-direction: initial;
    }
    &__main {
      flex-direction: column;
    }
    &__content {
      margin-top: 20px;
    }
  }
}
@media (max-width: 767px) {
  .settings {
    padding-bottom: 100px;
    .other {
      display: block;
  }
  }
}
@media (max-width: 480px) {
  .settings {
    .security {
      &__form {
        margin-top: 26px;
      }
      &__row {
        margin-top: 26px;
        .security__field {
          margin-bottom: 26px;
        }
      }
    }
  }
}