

.main {
  padding: 42px 79px 42px calc(256px + 79px);
  background-color: var(--gray-100);
  width: 100%;
  min-height: 100vh;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  color: var(--dark-500);
}

.white-box {
  width: 100%;
  border-radius: 8px;
  background-color: var(--light-100);
  padding: 20px;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}
@media (max-width: 1200px) {
  .main {
    padding: 42px 18px 42px calc(256px + 18px);
  }
}
@media (max-width: 767px) {
  .main {
    padding: 27px;
  }
}
@media (max-width: 380px) {
  .main {
    padding: 17px;
  }
}