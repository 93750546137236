.signup {
  &__verify {
    max-width: 396px;
    width: 100%;
    margin: 0px auto;
  }
  &__pin {
    width: 100%;
    overflow: hidden;
    margin-top: 32px;
    swd-pin-field {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-error {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: var(--danger-200);
      margin-top: 12px;
    }
    &.error {
      input {
        color: var(--danger-200);
        background: rgba(255, 188, 153, 0.25);
      }
    }
    input {
      font-weight: 600;
      font-size: 48px;
      line-height: 48px;
      color: var(--dark-100);
      width: 56px;
      height: 72px;
      margin-right: 12px;
      border-radius: 12px;
      outline: none;
      text-align: center;
      background: #EFEFEF;
      border-radius: 12px;
      border: 2px solid transparent;
      transition: all 0.3s;
      &:focus {
        background: #FCFCFC;
        border: 2px solid #9A9FA5;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
@media (max-width: 455px)  {
  .signup {
      &__verify {
        max-width: 300px;
      }
      &__pin {
        input {
          width: 40px;
          height: 56px;
          font-size: 32px;
        }
      }
  }
}